  .home-page-main-container {
    display: flex;
    gap: 20px;
    padding: 20px;
    background-color: #f7f9fc;
    height: calc(100vh - 140px);
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none; 
}

.home-page-main-container::-webkit-scrollbar {
    display: none;
}

  
  .home-page-left-container {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .home-page-right-container {
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .welcome-text {
    font-size: 24px;
    font-weight: 600;
  }
  
  .section-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .session-cards {
    display: flex;
    gap: 20px;
  }
  
  .session-card {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .session-details {
    display: flex;
    gap: 15px;
  }
  
  .session-img-container {
    width: 80px;
  }
  
  .profile-img {
    width: 100%;
    border-radius: 10px;
  }
  
  .session-info {
    flex: 1;
  }
  
  .session-name-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .session-name {
    font-size: 16px;
    font-weight: 600;
  }
  
  .session-role {
    font-size: 14px;
    color: #888;
  }
  
  .session-datetime {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #666;
  }
  
  .session-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  
  .join-btn {
    background-color: #28a745;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .duration {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .time-invested-container {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
  }
  
  .chart-container {
    margin-top: 15px;
  }
  
  .stats-container {
    display: flex;
    gap: 20px;
  }
  
  .stats-card {
    flex: 1;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #fff;
  }
  
  .stats-icon {
    font-size: 30px;
    color: #6a5af9;
  }
  
  .stats-title {
    font-size: 14px;
    color: #666;
  }
  
  .stats-value {
    font-size: 18px;
    font-weight: 600;
  }
  
  .calendar-container {
    background-color: #fff;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
  }
  
  .book-session {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #6a5af9;
    font-weight: 600;
  }
  
  .session-icon {
    font-size: 24px;
  }


.meeting-page-container {
  width: 100%;
  /* padding: 20px; */
  background-color: #f7f9fc;
}


.meeting-container-header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.meeting-details {
  display: flex;
  flex-direction: column;
}

.meeting-title {
  font-size: 18px;
  font-weight: bold;
}

.meeting-host {
  font-size: 14px;
  color: #555;
}

.back-button {
  cursor: pointer;
}

.meeting-container {
  display: flex;
  gap: 20px;
}


.video-container {
  width: 70%;
  max-width: 100%; 
}

.chat-container {
  width: 30%;
  height:calc(100vh - 220px);
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.chat-header {
  background-color: #f1f3f4;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #e0e0e0;
}

.chat-messages {
  padding: 15px;
  flex-grow: 1;
  overflow-y: auto;
  max-height: 300px; 
  scrollbar-width: none;
  -ms-overflow-style: none; 
}

.chat-messages::-webkit-scrollbar {
    display: none;
}


.chat-message {
  margin-bottom: 10px;
  font-size: 14px;
}

.chat-message-others {
  background-color: #f1f3f4;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.chat-message-user {
  background-color: #e7f3fe;
  padding: 8px;
  border-radius: 8px;
  text-align: right;
  margin-bottom: 10px;
}

.chat-input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}

.chat-input {
  flex-grow: 1;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 8px 12px;
  font-size: 14px;
  outline: none;
}

.chat-send-btn {
  background-color: #1a73e8;
  border: none;
  color: white;
  padding: 8px 16px;
  margin-left: 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
}

.chat-send-btn:hover {
  background-color: #1669c1;
}


.session-number-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f5; 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
  margin: 20px 0;
  gap: 20px;
}


.session-number-cards > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  height: 110px;
}

.session-number-cards > div:hover {
  transform: translateY(-5px); 
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15); 
}

.session-number-cards .MdComputer {
  font-size: 30px;
  color: #4a90e2;
  margin-bottom: 10px;
}

.session-number-cards div div {
  text-align: center;
}

.session-number-cards div div div:first-child {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.session-number-cards div div div:last-child {
  font-size: 22px;
  color: #4a90e2; 
  font-weight: bold;
}

.my-courses-container {
  background-color: #fff; 
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  font-family: 'Roboto', sans-serif;
}

.my-courses-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
}

.my-courses-header h3 {
  font-size: 22px;
  font-weight: 600;
  color: #333;
}

.view-all-btn {
  background-color: #007bff;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-all-btn:hover {
  background-color: #0056b3; 
}

.course-lists {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.course-item {
  font-size: 14px;
  color: #555;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e0;
  transition: color 0.3s ease, padding-left 0.3s ease;
}

.course-item:last-child {
  border-bottom: none;
}

.course-item:hover {
  color: #007bff; 
  padding-left: 10px; 
  cursor: pointer;
}
