/* General Styling */
.login-page * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .login-page {
    font-family: 'Arial', sans-serif;
    background-color: #f0f4f8;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-container {
    display: flex;
    width: 90%;
    max-width: 1000px;
    background-color: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
  
  .login-left-section {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .login-logo img {
    width: 120px;
    margin-bottom: 40px;
  }
  
  .login-title {
    font-size: 28px;
    color: #2c3e50;
    margin-bottom: 10px;
  }
  
  .login-subtitle {
    font-size: 16px;
    color: #34495e;
    margin-bottom: 30px;
  }
  
  .login-form-group {
    margin-bottom: 20px;
  }
  
  .login-input {
    width: 100%;
    padding: 12px 15px;
    font-size: 16px;
    border: 1px solid #d1d9e6;
    border-radius: 6px;
    transition: border-color 0.3s ease;
  }
  
  .login-input:focus {
    border-color: #4b7bec;
    outline: none;
  }
  
  .login-form-button {
    background-color: #4b7bec;
    color: white;
    font-size: 16px;
    padding: 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .login-form-button:hover {
    background-color: #3867d6;
  }
  
  .login-forgot-password {
    color: #4b7bec;
    font-size: 14px;
    display: block;
    margin-top: 10px;
    text-align: center;
    text-decoration: none;
  }
  
  .login-forgot-password:hover {
    text-decoration: underline;
  }
  
  /* Right Section - Illustration */
  .login-right-section {
    flex: 1;
    background: linear-gradient(180deg, #0066ff 0%, #6699ff 100%);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-illustration {
    width: 80%;
    height: auto;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .login-container {
      flex-direction: column;
    }
  
    .login-right-section {
      display: none;
    }
  }
  