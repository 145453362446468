/* 
.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .header {
    background-color: #333;
    color: #fff;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .sidebar-toggle {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .main-container {
    display: flex;
    flex: 1;
  }
  
  .sidebar {
    width: 200px;
    background-color: #fff;
    padding: 1rem;
    transition: width 0.3s ease;
  }
  
  .sidebar.collapsed {
    width: 60px; 
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 1rem 0;
    display: flex;
    align-items: center;
  }
  
  .sidebar ul li a {
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
  }
  
  .sidebar ul li a .link-text {
    margin-left: 10px;
  }
  
  .sidebar.collapsed .link-text {
    display: none; 
  }
  
  .content {
    flex: 1;
    padding: 1rem;
  }
  
  button {
    padding: 0.5rem;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
  }  

  
.link {
    color: #333; 
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
  }
  
  .link:hover {
    background-color: #f0f0f0;
  }
  
  .active {
    background-color: #007bff; 
    color: white; 
  }
  
  .active .link-text {
    color: white; 
  }
  
  .sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 1rem;
    transition: width 0.3s ease;
  }
  
  .sidebar.collapsed {
    width: 60px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 1rem 0;
  }
   */

   /* Layout.css */
.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    background-color: #333;
    color: #fff;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-toggle {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
}

.main-container {
    display: flex;
    flex: 1;
}

.sidebar {
    width: 180px;
    background-color: #f4f4f4;
    padding: 1rem;
    transition: width 0.3s ease;
}

.sidebar.collapsed {
    width: 36px;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin: 8px 0;
    display: flex;
    align-items: center;
}

.sidebar ul li a {
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
    padding: 10px;
    width: 100%;
    border-radius: 8px;
}

.sidebar ul li a .link-text {
    margin-left: 10px;
}

.sidebar.collapsed .link-text {
    display: none; /* Hide text when collapsed */
}

.content {
    flex: 1;
    padding: 15px;
    height: calc(100vh - 104px);
    overflow-y: scroll; /* Enable vertical scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.content::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}


button {
    padding: 0.5rem;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
}

/* Link styling */
.link {
    color: #333; 
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
}

/* .link:hover {
    background-color: #f0f0f0;
} */

/* Active link styling */
.active {
    background-color: #007bff; /* Active link background */
    color: white; /* Active link text color */
}

.active .link-text {
    color: white; /* Ensure the text color of active link is white */
}

.sidebar-li:hover{
  cursor:pointer;
}
