  .header {
    background-color: #fff;
    color: #000;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1; 
    height:74px
}
.header-logo-container{
padding:10px 0px;
}
.header-logo{
height:50px;
}
  
  .sidebar-toggle {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
  }
  