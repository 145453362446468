.course-card {
    width: 260px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    margin: 15px;
  }
  
  .course-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .card-image {
    position: relative;
    height: 160px;
    overflow: hidden;
  }
  
  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-rating {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
    color: #ffbb00;
    display: flex;
    align-items: center;
  }
  
  .fa-star {
    margin-left: 5px;
    color: #ffbb00;
  }
  
  .card-body {
    padding: 15px;
    text-align: center;
  }
  
  .course-name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
  }
  
  .expertise {
    font-size: 14px;
    color: #666;
    margin-bottom: 12px;
  }
  
  .card-info {
    font-size: 13px;
    color: #888;
    text-align: left;
  }
  
  .card-info p {
    margin: 5px 0;
  }
  
  .card-actions {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .details-btn,
  .book-now-btn {
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .details-btn {
    background-color: transparent;
    color: #6a5af9;
    border: 1px solid #6a5af9;
  }
  
  .book-now-btn {
    background-color: #6a5af9;
    color: #fff;
  }
  
  .details-btn:hover {
    background-color: #6a5af9;
    color: #fff;
  }
  
  .book-now-btn:hover {
    background-color: #5847c9;
  }
  

   /* .course-card {
    width: 100%;
    max-width: 260px; 
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    margin: 15px;
    padding: 20px; 
  }
  
  .course-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .card-image {
    position: relative;
    height: 180px;
    overflow: hidden;
    border-radius: 15px; 
  }
  
  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-rating {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
    color: #ffbb00;
    display: flex;
    align-items: center;
  }
  
  .fa-star {
    margin-left: 5px;
    color: #ffbb00;
  }
  
  .card-body {
    padding: 15px;
    text-align: center;
  }
  
  .course-name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333;
  }
  
  .expertise {
    font-size: 14px;
    color: #666;
    margin-bottom: 12px;
  }
  
  .card-info {
    font-size: 13px;
    color: #888;
    text-align: left;
  }
  
  .card-info p {
    margin: 5px 0;
  }
  
  .card-actions {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .details-btn,
  .book-now-btn {
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .details-btn {
    background-color: transparent;
    color: #6a5af9;
    border: 1px solid #6a5af9;
  }
  
  .book-now-btn {
    background-color: #6a5af9;
    color: #fff;
  }
  
  .details-btn:hover {
    background-color: #6a5af9;
    color: #fff;
  }
  
  .book-now-btn:hover {
    background-color: #5847c9;
  }
   */