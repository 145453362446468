.course-page {
    display: flex;
    padding: 20px;
  }
  
  .filters {
    width: 200px;
    margin-right: 20px;
  }
  
  .filters h3 {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  
  .filters select,
  .filters input {
    margin-bottom: 10px;
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Style for filter option labels */
  .filter-option {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    color: #555;
  }
  
  .filter-option input[type="checkbox"],
  .filter-option input[type="radio"] {
    margin-right: 10px;
  }
  
  /* Adjust spacing and padding for checkboxes */
  input[type="checkbox"],
  input[type="radio"] {
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
  }
  
  .filters label {
    font-size: 14px;
    margin-left: 5px;
    color: #333;
  }
  
  
  .course-list {
    flex: 1;
  }
  
  .course-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width:100%
  }
  
  .course-header h1 {
    font-size: 24px;
  }
  
  .course-controls {
    display: flex;
    gap: 10px;
  }
  
  .clear-filters {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
  }
  
  .course-skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .skeleton {
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
  }
  
  .skeleton:nth-child(1) {
    width: 50%;
  }
  
  .skeleton:nth-child(2) {
    width: 80%;
  }
  
  .skeleton:nth-child(3) {
    width: 70%;
  }
  
  .skeleton:nth-child(4) {
    width: 60%;
  }


  .course-list {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    gap: 20px; 
    padding: 0px 20px;
    background-color: #f9f9f9;
  }
  
  
  @media (min-width: 768px) {
    .course-list {
      justify-content: space-between;
    }
  }
  
  @media (max-width: 768px) {
    .course-list {
      justify-content: center;
    }
  }
  
  